<template>
  <div class="app">
    <the-heading></the-heading>
    <div class="app-content">
      <left-menu @choose-sub-menu="chooseSubMenu"></left-menu>
      <div class="right-content">
        <top-menu v-if="topMenus.length > 0" :menus="topMenus" />
        <keep-alive exclude="WorkCounts,ResumePrint">
          <router-view class="app-router-view"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from './menus/top'
import LeftMenu from './menus/left'
import TheHeading from '@/components/base/TheHeading.vue'
export default {
  components: { TopMenu, LeftMenu, TheHeading },
  name: 'app',
  data () {
    return {
      topMenus: []
    }
  },
  computed: {
    theme () {
      return this.$store.getters.theme
    }
  },
  watch: {
    theme: {
      immediate: true,
      handler (theme) {
        for (let theme of this.$store.getters.themeList) {
          document.body.classList.remove(theme.key + '-theme')
        }
        document.body.classList.add(theme + '-theme')
      }
    }
  },
  methods: {
    chooseSubMenu (menu) {
      this.topMenus = menu ? menu.children : []
    }
  }
}
</script>

<style lang="less">
@import '../../styles/theme.less';
body, html {height: 100%;}
.app-router-view {
  flex: 1;
  height: 0;
}
.right-content {
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
}
.fm-title {
  background: rgba(0, 0, 0, 0) !important;
}
.app {
  background-color: @color-background-base;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.app-content {
  height: calc(100% - @the-heading-height);
  max-height: calc(100% - @the-heading-height);
  display: flex;
  align-items: flex-start;
  position: relative;
}

.white-theme {
    .app-router-view { background-color: @color-background-page-white; }
}
.dark-theme {
    .app-router-view { background-color: @color-background-page-dark; }
}
.pink-theme {
    &.app { background-color: #FFF; }
    .app-router-view { background-color: @color-background-page-pink; }
}
.pink-theme {
    &.app { background-color: #FFF; }
    .app-router-view { background-color: @color-background-page-blue; }
}
</style>
